import React, { Component } from "react";
import Helmet from "react-helmet";

import Label from "components/Elements/Label";
import MLProjects from "components/Tracks/ML";

import TitleContainer from "components/Elements/TitleContainer";

class Web extends Component {
  render() {
    return (
      <div>
        <Helmet
          title="Learn Data Science | Enlight"
          meta={[
            {
              name: "description",
              content:
                "Learn Data Science by building fun mini-projects within a community of 2,000 other aspiring developers. Learn modern applications within machine learn like image recognition, object detection, sentiment analysis, and classification to create robust algorithms.",
            },
          ]}
        />
        <TitleContainer>
          <br />

          <h1 className="playfair">Learn Data Science</h1>
          <br />

          <Label>Python</Label>
          <br />
        </TitleContainer>
        <br />
        <MLProjects />
      </div>
    );
  }
}

export default Web;
